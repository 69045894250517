<template>
    <div class="flex justify-center w-full">
        <div class="pb-2 brand_inner_box sm:pb-4">
            <img
                :src="item.resourcePicture"
                :alt="item.pictureName"
                v-for="item in imgObject"
                :key="item.resourceDetailId"
                @click="goLink(item)"
                style="width:100%;height:auto"
                class="observer_img"
                @load="loadImage"
            />
        </div>
    </div>
</template>

<script>
import { SERVICE } from "../../../../common/constant"
export default {
    data() {
        return {
            imgIndex: -1,
            imgObject: [], // 存储所有图片的对象
            loadIndex: 0,
        };
    },
    created() {
        // this.getBrand()
        this.getBrandPage()
    },
    methods: {
        // 页面跳转
        goLink(item) {
            if (item.ifStart) {
                window.open(item.linkUrl)
            }

        },
        getBrandPage() {
            this.$request({
                methods: "get",
                url: this.$requestPath.getResourceDetail,
                params: {
                    resourceId: SERVICE
                }
            }).then(res => {
                console.log("服务门店", res)
                this.imgObject = []
                if (res.code == 0) {
                    this.imgObject.push(...res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '获取服务门店数据失败' })
                }
            }).catch(error => {
                console.log("数据请求失败，请稍后再试", error)
            })
        },
        loadImage() {
            this.loadIndex++
            if (this.loadIndex === 1) {
                this.observer()
            }
        },
        observer() {
            let list = document.querySelectorAll('.observer_img')
            let observer = new IntersectionObserver((entries) => {
                entries.forEach((element) => {
                    if (element.isIntersecting) {
                        element.target.classList.add('animation_show') // 增加show类名
                        observer.unobserve(element.target) // 移除监听
                    }
                })
            }, {
                threshold: 0.1
            })
            list.forEach((item) => observer.observe(item))
        }
    },
};
</script>

<style lang='scss' scoped>
.mask-enter-active,
.mask-leave-active {
    transition: all 2s ease;
}
.mask-enter,
.mask-leave-to {
    // transform: translateY(2.5rem);
    opacity: 0;
}

.content-enter-active,
.content-leave-active {
    transition: all 1.2s ease;
}
.content-enter,
.content-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

@media screen and (max-width: 640px) {
    .brand_inner_box {
        width: 100%;
    }
}
@media screen and (min-width: 640px) {
    .brand_inner_box {
        width: 50rem;
    }
}
</style>